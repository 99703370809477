@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fef6e4;
  overflow-x: hidden !important;

}

::-webkit-scrollbar {
  display: none;
}
#element {
  scrollbar-width: none;
}

a {
  text-decoration: inherit;
  color: #001858
}


.logoHeader {
  color: #fef6e4;
  font-size: 10vw;
  line-height: 10vw;
  z-index: 5;
  font-family: "Lato";
  font-weight: 900;
}

.subHeader {
  color: #fef6e4;
  font-size: 4vw;
  line-height: 4vw;
  z-index: 5;
  font-family: "Lato" !important
}


.projectDesc {
  text-align: center;
  z-index: 6;
  padding-left: 25px;
  padding-right: 25px;
  text-decoration: none;

}

.navbarBlobLink {
  color: #001858;
  font-family: "Lato"
}

.projectDescHighlight {
  display: inline;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .logoHeader {
    font-size: 4rem;
    line-height: 4rem;
  }

  
.subHeader {
  font-size: 2rem;
  line-height: 2rem;
}
}






.iframeWrap {
  width: auto;
  height: auto;
  overflow: hidden;
padding: 0;
margin: 0;
  border: 5px #8bd3dd solid;
  border-radius: 10px;
  position: relative;
}

.scaled-frame {
z-index: 50;
  overflow: auto;
  width: 168%;
  height: 1000px;
  border: 0px;
  padding: 0px;
  margin-bottom: -405px;
}

.scaled-frame {
  background-color: white;
  zoom: 0.6;
  -moz-transform: scale(0.6);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.6);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.6);
  -webkit-transform-origin: 0 0;
}


@media screen and (max-width: 750px) {
  .scaled-framze {
    width: 126%;
    height: 1000px;
    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
  }
}

.code {
  background-color: black;
  color: yellow;
  font-family: 'Courier New', Courier, monospace;
}

.z5 {
  z-index: 5 !important;
}

.projectDescGrid {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  min-width: 320px;
  padding-bottom: 20px
}

.projectSS {
  border-radius: 15px;
  border: 5px solid #8bd3dd;
  width: 90%;
}

.contactItem {
  transition: 0.3s
}

.contactItem:hover{
  transform: scale(1.1);
}

.contactContainer:hover > :not(:hover) {
  opacity: .6;
  transform: scale(0.9);
}